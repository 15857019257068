import React from 'react';
import '../App.css';

const logoTravelLeaders = 'https://agents.travelleaders.com/Reviews/images/LogoTL_59.png';

const ThankYou = () => {
    return (
        <div className="ThankYou">
            <header className="bg-white shadow-md p-10 text-center relative">
                <a href="https://travelleaders.com" target="_blank" rel="noopener noreferrer">
                    <img
                        src={logoTravelLeaders}
                        alt="Travel Leaders Logo"
                        className="text-center"
                    />
                </a>
            </header>

            <div className="bg-white shadow-md p-20 mt-24 mb-10 w-11/12 max-w-2xl mx-auto text-center relative">
                <div className="relative">
                    <h1 className="text-3xl mb-2 pb-4 text-tlBlue">Thank you!</h1>
                    <h2 className="text-lg mb-2">Your response has been recorded</h2>
                </div>
            </div>
        </div>
    );
};

export default ThankYou;
