import axios from 'axios';

class ApiMethods {
    constructor() {
        this.apiUser = process.env.REACT_APP_API_USER;

        const hostname = window.location.hostname;

        if (hostname === 'localhost') {
            // LOCAL
            this.apiUrl = process.env.REACT_APP_API_URL_LOCAL;
            this.apiKey = process.env.REACT_APP_API_KEY_LOCAL;
            this.destinationsApiUrl = process.env.REACT_APP_DESTINATIONS_API_URL_LOCAL;
            this.destinationsApiKey = process.env.REACT_APP_DESTINATIONS_API_KEY_LOCAL;
        } else if (hostname.includes('dev.agents.travelleaders')) {
            // DEV
            this.apiUrl = process.env.REACT_APP_API_URL_DEV;
            this.apiKey = process.env.REACT_APP_API_KEY_DEV;
            this.destinationsApiUrl = process.env.REACT_APP_DESTINATIONS_API_URL_DEV;
            this.destinationsApiKey = process.env.REACT_APP_DESTINATIONS_API_KEY_DEV;
        } else if (hostname.includes('qa.agents.travelleaders')) {
            // QA
            this.apiUrl = process.env.REACT_APP_API_URL_QA;
            this.apiKey = process.env.REACT_APP_API_KEY_QA;
            this.destinationsApiUrl = process.env.REACT_APP_DESTINATIONS_API_URL_QA;
            this.destinationsApiKey = process.env.REACT_APP_DESTINATIONS_API_KEY_QA;
        } else {
            throw new Error('Host is not supported');
        }

        this.token = null;
        this.tokenExpiration = null;
    }

    async login() {
        try {
            const response = await axios.post(`${this.apiUrl}/Login`, {
                ApiUser: this.apiUser,
                ApiKey: this.apiKey,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });


            this.token = response.data.token;
            this.tokenExpiration = Date.now() + (60 * 60 * 1000); // 60 minutes in milliseconds

            console.log('URL:', this.apiUrl);
            console.log('Token:', this.token);

            return response.data;
        } catch (error) {
            console.error('Error during login:', error);
            throw error;
        }
    }

    async getReview(appsGuid) {
        if (!this.token || this.tokenExpiration < Date.now()) {
            await this.login();
        }

        try {
            const url = `${this.apiUrl}/ConsumerComm/Review/${appsGuid}`;
            
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error during getReview:', error.response ? error.response.data : error.message);
            throw error;
        }
    }

    async getDestinations() {
        if (!this.token || this.tokenExpiration < Date.now()) {
            await this.login();
        }

        try {
            const soapRequest = `<?xml version="1.0" encoding="utf-8"?>
        <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
          <soap:Body>
            <GetDestinationsV2 xmlns="http://service.travelleaders.com/Agency/agents.asmx/">
              <ApiKey>${this.destinationsApiKey}</ApiKey>
              <AgencyID>0</AgencyID>
              <Locality></Locality> <!-- Si Locality no es necesario, puedes dejarlo vac�o -->
              <Brand>vacation</Brand>
            </GetDestinationsV2>
          </soap:Body>
        </soap:Envelope>`;

            const response = await axios.post(this.destinationsApiUrl, soapRequest, {
                headers: {
                    'Content-Type': 'text/xml; charset=utf-8',
                    'SOAPAction': 'http://service.travelleaders.com/Agency/agents.asmx/GetDestinationsV2'
                },
                responseType: 'text' // Wait for the XML
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async submitReview(reviewData) {
        if (!this.token || this.tokenExpiration < Date.now()) {
            await this.login();
        }

        try {
            const response = await axios.post(`${this.apiUrl}/ConsumerComm/Review/Save`, reviewData, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getSurvey(appsGuid, requestId) {
        if (!this.token || this.tokenExpiration < Date.now()) {
            await this.login();
        }

        try {
            const response = await axios.get(`${this.apiUrl}/ConsumerComm/Survey/${appsGuid}/${requestId}`, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async submitSurvey(surveyData) {
        if (!this.token || this.tokenExpiration < Date.now()) {
            await this.login();
        }

        try {
            const response = await axios.post(`${this.apiUrl}/ConsumerComm/Survey/Save`, surveyData, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getCustomUrl(customUrlData) {
        if (!this.token || this.tokenExpiration < Date.now()) {
            await this.login();
        }

        try {
            const response = await axios.get(`${this.apiUrl}/ConsumerComm/CustomURL/${customUrlData}`, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async postContacted(appsGuid, requestId, userResponse) {
        if (!this.token || this.tokenExpiration < Date.now()) {
            await this.login();
        }

        try {
            const response = await axios.post(`${this.apiUrl}/ConsumerComm/Contacted`, {
                Token: appsGuid,
                RequestId: requestId,
                Contacted: userResponse,
                UserIP: "0.0.0.0",
                RequestUrl: `${this.apiUrl}/ConsumerComm/Contacted/${appsGuid}/${requestId}/rsp=${userResponse}`
            }, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

}

const apiMethodsInstance = new ApiMethods();

export default apiMethodsInstance;
