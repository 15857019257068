import React from 'react';
import '../App.css';
import { useLocation } from 'react-router-dom';

const logoTravelLeaders = 'https://agents.travelleaders.com/Reviews/images/LogoTL_59.png';

const ErrorPage = () => {
    const location = useLocation();
    const { header, message, linkPrefix, linkText, linkHref, linkSuffix } = location.state || {};

    return (
        <div className="ErrorPage">
            <header className="bg-white shadow-md p-10 text-center relative">
                <a href="https://travelleaders.com" target="_blank" rel="noopener noreferrer">
                    <img
                        src={logoTravelLeaders}
                        alt="Travel Leaders Logo"
                        className="text-center"
                    />
                </a>
            </header>

            <div className="bg-white shadow-md p-20 mt-24 mb-10 w-11/12 max-w-2xl mx-auto text-center relative">
                <div className="relative">
                    <h1 className="text-3xl mb-2 pb-4 text-tlBlue">{header || "We're sorry!"}</h1>
                    <h2 className="text-lg mb-2">
                        {message || "It looks like the agent you have selected is no longer a part of our network. Don't worry, we have plenty of other agents to choose from."}
                        <br />
                        {linkPrefix && `${linkPrefix} `}
                        <a
                            className="text-lg mb-2 text-tlBlue"
                            href={linkHref || "https://www.travelleaders.com/find_travel_agent"}
                            target="_blank"
                            rel="noopener noreferrer"
                            id="lnkFindAgents"
                        >
                            {linkText || "Find Other Agents Now"}
                        </a>
                        {linkSuffix && ` ${linkSuffix}`}
                    </h2>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
