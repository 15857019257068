import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ReviewsLanding from './pages/ReviewsLanding';
import SurveyLanding from './pages/SurveyLanding';
import ThankYou from './pages/ThankYou';
import ErrorPage from './pages/ErrorPage';
import WrongUrl from './pages/WrongUrl';
import Home from './pages/Home';
import './App.css';

function App() {
    return (
        <Router>
            <AppRoutes />
        </Router>
    );
}

function AppRoutes() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const appsGuid = searchParams.get('agt');
        const requestId = searchParams.get('req');
        const response = searchParams.get('rsp');
        const pg = searchParams.get('pg');

        try {
            // OLD Review
            if (location.pathname === '/Reviews/agent_review.aspx' && appsGuid) {
                navigate(`/review/${appsGuid}`);
            }

            // OLD Survey
            if (location.pathname === '/Reviews/agent_experience.aspx' && requestId) {
                navigate(`/survey/${appsGuid}/${requestId}`);
            }

            // OLD Contacted
            if (location.pathname === '/Reviews/thank_you.aspx' && pg === '24' && requestId) {
                navigate(`/contacted/${appsGuid}/${requestId}?rsp=${response}`);
            }
        } catch (error) {
            console.error('Error during redirection:', error);
            navigate('/error');
        }
    }, [location, navigate]);

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/review/:appsGuid" element={<ReviewsLanding />} />
            <Route path="/survey/:appsGuid/:requestId" element={<SurveyLanding />} />
            <Route path="/contacted/:appsGuid/:requestId" element={<Home />} />
            <Route path="/thankyou" element={<ThankYou />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/wrongUrl" element={<WrongUrl />} />
            <Route path="*" element={<WrongUrl />} />
        </Routes>
    );
}

export default App;