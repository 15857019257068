import { useEffect, useState } from 'react';
import StarRatings from 'react-star-ratings';
import '../App.css';
import ApiMethods from '../ApiHelper/ApiMethods';
import apiMethodsInstance from '../ApiHelper/ApiMethods';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';

function SurveyLanding() {
    const location = useLocation();
    const { appsGuid: paramsAppsGuid } = useParams();
    const { requestId: paramsRequestId } = useParams();
    const { appsGuid, requestId } = location.state || { appsGuid: paramsAppsGuid, requestId: paramsRequestId };
    const navigate = useNavigate();

    const [rating, setRating] = useState(0);
    const [surveyDescription, setSurveyDescription] = useState('');
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});
    const [surveyData, setsurveyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedStatus, setselectedStatus] = useState('');
    const [yesReasons, setYesReasons] = useState({
        yesReason1: false,
        yesReason2: false,
        yesReason3: false,
        yesReason4: false,
        yesReason5: false,
        yesReason6: false,
    });
    const [yesOtherReason, setYesOtherReason] = useState('');

    const [noReasons, setNoReasons] = useState({
        noReason1: false,
        noReason2: false,
        noReason3: false,
        noReason4: false,
        noReason5: false,
        noReason6: false,
    });
    const [noOtherReason, setNoOtherReason] = useState('');

    const changeRating = (newRating) => {
        setRating(newRating);
    };

    const handleDescriptionChange = (event) => {
        setSurveyDescription(event.target.value);
    };

    const handleRadioChange = (e) => {
        setselectedStatus(e.target.value);
    };

    const handleYesOtherReasonChange = (o) => {
        setYesOtherReason(o.target.value);
    }

    const handleNoOtherReasonChange = (o) => {
        setNoOtherReason(o.target.value);
    }

    const [starDimension, setStarDimension] = useState('50px');

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevents the default form submission behavior
        setErrors({});
        setError('');

        let errors = {};

        if (rating === 0) errors.rating = true;
        if (surveyDescription.trim() === '') errors.surveyDescription = true;
        if (surveyDescription.length > 2000) errors.surveyDescriptionLength = true;
        if (!selectedStatus) errors.selectedStatus = true;

        const selectedYesReasons = Object.values(yesReasons).some(reason => reason) || yesOtherReason.trim() !== '';
        const selectedNoReasons = Object.values(noReasons).some(reason => reason) || noOtherReason.trim() !== '';

        if (selectedStatus === 'Y' || selectedStatus === 'P') {
            if (!selectedYesReasons) errors.yesReasons = 'Please select an option';
            if (yesReasons.yesReason6 && yesOtherReason.trim() === '') errors.yesOtherReason = 'Please provide a reason';
        }
        if (selectedStatus === 'N') {
            if (!selectedNoReasons) errors.noReasons = 'Please select an option';
            if (noReasons.noReason6 && noOtherReason.trim() === '') errors.noOtherReason = 'Please provide a reason';
        }
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            setError('All fields must be completed before submitting.');
            return;
        }

        const submittedInfo = {
            RequestId: apiMethodsInstance.surveyAgentIdTest,
            OverallRating: rating,
            ClientComment: surveyDescription,
            Booked: selectedStatus,
            BookedReason: [
                ...(yesReasons.yesReason1 ? [{ AnswerText: "He or She was knowledgeable about my travel destination", AddlComent:"" }] : []),
                ...(yesReasons.yesReason2 ? [{ AnswerText: "Working with this agent was convenient and easy", AddlComent: "" }] : []),
                ...(yesReasons.yesReason3 ? [{ AnswerText: "The agent provided added value in the planning process", AddlComent: "" }] : []),
                ...(yesReasons.yesReason4 ? [{ AnswerText: "The agent crafted the perfect trip for me", AddlComent: "" }] : []),
                ...(yesReasons.yesReason5 ? [{ AnswerText: "I was referred to this agent from a friend or family member", AddlComent: "" }] : []),
                ...(yesReasons.yesReason6 ? [{ AnswerText: "Other", AddlComent: yesOtherReason }] : []),

                ...(noReasons.noReason1 ? [{ AnswerText: "Poor communication - I never heard back from this agent", AddlComent: "" }] : []),
                ...(noReasons.noReason2 ? [{ AnswerText: "The agent didn�t offer enough value or knowledge about my trip", AddlComent: "" }] : []),
                ...(noReasons.noReason3 ? [{ AnswerText: "Working with this agent was expensive", AddlComent: "" }] : []),
                ...(noReasons.noReason4 ? [{ AnswerText: "I ended up not taking the trip", AddlComent: "" }] : []),
                ...(noReasons.noReason5 ? [{ AnswerText: "I planned the trip by myself", AddlComent: "" }] : []),
                ...(noReasons.noReason6 ? [{ AnswerText: "Other", AddlComent: noOtherReason }] : []),
            ]
        };

        try {
            const response = await ApiMethods.submitSurvey(submittedInfo);
            navigate('/thankyou');
        } catch (error) {
            setError('Error submitting review. Please try again.');
        }
    };

    // Getting data from the API
    useEffect(() => {
        const fetchSurveyData = async () => {
            try {
                await ApiMethods.login();
                const surveyResponse = await ApiMethods.getSurvey(appsGuid, requestId);
                const surveyParsedData = JSON.parse(surveyResponse.data);
                setsurveyData(surveyParsedData);

                if (!surveyParsedData.ReviewsEligible && surveyParsedData.Agency.RecordType === "GTC") {
                    navigate('/error', {
                        state: {
                            header: "We're sorry!",
                            message: "The Global Travel Collection travel agent you are looking for does not yet have a published profile.",
                            linkPrefix: "Please visit",
                            linkText: "globaltravelcollection.com/contact-us",
                            linkHref: "https://www.globaltravelcollection.com/contact",
                            linkSuffix: "to be connected with your agent. Thank you.",
                        },
                    });
                    return;
                }

                if (!surveyParsedData.ReviewsEligible && surveyParsedData.Agency.RecordType === "Barrhead") {
                    navigate('/error', {
                        state: {
                            header: "We're sorry!",
                            message: "The Barrhead travel agent you are looking for does not yet have a published profile.",
                            linkPrefix: "Please visit",
                            linkText: "barrheadtravel.co.uk",
                            linkHref: "https://www.barrheadtravel.co.uk/",
                            linkSuffix: "to be connected with your agent. Thank you.",
                        },
                    });
                    return;
                }

                if (!surveyParsedData.ReviewsEligible && surveyParsedData.Agency.RecordType === "Altour") {
                    navigate('/error', {
                        state: {
                            header: "We're sorry!",
                            message: "The Altour travel agent you are looking for does not yet have a published profile.",
                            linkPrefix: "Please visit",
                            linkText: "altour.com",
                            linkHref: "https://www.altour.com",
                            linkSuffix: "to be connected with your agent. Thank you.",
                        },
                    });
                    return;
                }

                setLoading(false);
            } catch (error) {
                setLoading(false);
                navigate('/wrongUrl');
            }
        };

        fetchSurveyData();
    }, []);

    //Stars dimensions
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setStarDimension('25px');
            } else {
                setStarDimension('50px');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function formatDate(isoDateString) {
        const date = new Date(isoDateString);

        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    }

    function getLinkText(url) {
        if (url.includes('travelleaders')) {
            return 'Travelleaders';
        } else if (url.includes('vacation')) {
            return 'Vacation';
        } else {
            return '';
        }
    }

    let agencyBrand = '';
    if (loading === false) {
        agencyBrand = getLinkText(surveyData.Agency.HomePageLink);
    }

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <TailSpin
                    height="100"
                    width="100"
                    color="#0B829E"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                />
            </div>
        );
    }

    const profilePhotoUrl = surveyData ? surveyData.ProfilePhoto : '';

    return (
        <div className="ReviewsLanding">
            <header className="bg-white shadow-md p-10 text-center relative"></header>

            <div className="bg-white shadow-md p-20 mt-24 mb-10 w-11/12 max-w-2xl mx-auto text-center relative">
                <div className="relative">
                    <img
                        src={profilePhotoUrl}
                        alt="Profile"
                        className="absolute top-[-150px] left-1/2 transform -translate-x-1/2 w-32 h-32 object-cover rounded-lg shadow-lg"
                    />
                </div>
                <h2 className="text-lg mb-2 sm:font-lato sm:font-normal sm:text-[30px] sm:text-tlBlack sm:leading-[32px] sm:tracking-normal sm:mb-2">
                    {agencyBrand} Agent Experience Survey
                </h2>
                <h1 className="text-3xl font-bold text-tlBlue sm:font-lato sm:font-normal sm:text-[40px] sm:leading-[32px] sm:tracking-normal sm:mt-4">
                    {surveyData ? `${surveyData.FirstName} ${surveyData.LastName}` : ''}
                </h1>

                <p className="font-lato font-normal text-[16px] texl-tlDarkGray leading-[24px] tracking-normal text-center mt-10 sm:text-center">
                    Hello {surveyData.CustomerName},<br />
                    Our records indicate that you submitted an inquiry to <span className="font-bold">{surveyData ? `${surveyData.FirstName} ${surveyData.LastName}` : ''}</span> on <span className="text-bold">{formatDate(surveyData.ContactDate)}</span>.
                    Based on that inquiry, we'd love to hear about your experience working with <span className="font-bold">{surveyData ? `${surveyData.FirstName}` : ''}</span>.
                    We invite you to complete the following survey and share your experience. Thank you!
                </p>

                <p className="font-lato font-normal text-[16px] texl-tlDarkGray leading-[24px] tracking-normal mt-10">
                    How many stars would you give <span className="font-bold">{surveyData ? `${surveyData.FirstName} ${surveyData.LastName}` : ''}</span> as an agent? <span className={`font-bold ${errors.rating ? 'text-tlRed' : 'text-tlBlue'}`}>*</span>
                </p>
                <div className="flex items-center justify-center flex-col mt-4">
                    <StarRatings
                        rating={rating}
                        starRatedColor="#FFD700"
                        starEmptyColor="#DDD"
                        starHoverColor="#FFD700"
                        changeRating={changeRating}
                        numberOfStars={5}
                        starDimension={starDimension}
                        starSpacing="1px"
                        starRatedClassName="star-rated"
                    />
                </div>

                <div className="flex items-center justify-center flex-col mt-4">
                    <p className="font-lato font-normal text-[16px] texl-tlDarkGray leading-[24px] tracking-normal mt-10">
                        Do you have any recomendation for <span className="font-bold">{surveyData ? `${surveyData.FirstName}` : ''}</span>? <span className={`font-bold ${errors.surveyDescription ? 'text-tlRed' : 'text-tlBlue'}`}>*</span>
                    </p>

                    <textarea
                        value={surveyDescription}
                        onChange={handleDescriptionChange}
                        className={`mt-2 px-4 py-2 pt-2 w-full h-24 rounded-lg border ${errors.surveyDescription ? 'border-tlRed' : 'border-gray-500'} focus:outline-none focus:border-black`}
                    />
                    {errors.surveyDescriptionLength && <p className="text-tlRed text-xs">Please enter no more than 2000 characters</p>}
                </div>

                <div className="flex items-center justify-center flex-col mt-4">
                    <p className="font-lato font-normal text-[16px] texl-tlDarkGray leading-[24px] tracking-normal mt-10">
                        Have you booked or do you plan to book your trip using <span className="font-bold">{surveyData ? `${surveyData.FirstName}` : ''}</span>? <span className={`font-bold ${errors.yesOtherReason || errors.yesReasons || errors.noOtherReason || errors.noReasons ? 'text-tlRed' : 'text-tlBlue'}`}>*</span>
                    </p>
                    <div className="flex flex-col items-center mt-2">
                        <label className={`mt-2 ${selectedStatus === 'Y' ? 'text-tlBlue' : ''}`}>
                            <input type="radio" name="booking" value="Y" className="mr-2" checked={selectedStatus === 'Y'} onChange={handleRadioChange} />
                            Yes
                        </label>
                        <label className={`mt-2 ${selectedStatus === 'P' ? 'text-tlBlue' : ''}`}>
                            <input type="radio" name="booking" value="P" className="mr-2" checked={selectedStatus === 'P'} onChange={handleRadioChange} />
                            Not yet, but I plan to
                        </label>
                        <label className={`mt-2 ${selectedStatus === 'N' ? 'text-tlBlue' : ''}`}>
                            <input type="radio" name="booking" value="N" className="mr-2" checked={selectedStatus === 'N'} onChange={handleRadioChange} />
                            No
                        </label>
                        {errors.selectedStatus && <p className="text-tlRed text-xs pt-2">Please select an option</p>}
                    </div>
                </div>

                {selectedStatus === 'Y' || selectedStatus === 'P' ? (
                    <div>
                        <div className="flex items-center justify-center flex-col mt-4">
                            <p className="font-lato font-normal text-[16px] texl-tlDarkGray leading-[24px] tracking-normal mt-10">
                                What are the reasons you booked or will book with <span className="font-bold">{surveyData ? `${surveyData.FirstName}` : ''}</span>?
                            </p>
                        </div>

                        <div className="flex flex-col sm:flex-row justify-center mt-2 text-left text-s">
                            <div className="flex flex-col mr-0 sm:mr-4">
                                <label className="font-lato font-normal text-[14px] texl-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="yesReason1"
                                        className="mr-2"
                                        checked={yesReasons.yesReason1}
                                        onChange={() => setYesReasons({ ...yesReasons, yesReason1: !yesReasons.yesReason1 })}
                                    />
                                    He or She was knowledgeable about my travel destination
                                </label>
                                <label className="font-lato font-normal text-[14px] texl-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="yesReason2"
                                        className="mr-2"
                                        checked={yesReasons.yesReason2}
                                        onChange={() => setYesReasons({ ...yesReasons, yesReason2: !yesReasons.yesReason2 })}
                                    />
                                    Working with this agent was convenient and easy
                                </label>
                                <label className="font-lato font-normal text-[14px] texl-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="yesReason3"
                                        className="mr-2"
                                        checked={yesReasons.yesReason3}
                                        onChange={() => setYesReasons({ ...yesReasons, yesReason3: !yesReasons.yesReason3 })}
                                    />
                                    The agent provided added value in the planning process
                                </label>
                            </div>
                            <div className="flex flex-col mt-4 sm:mt-0 mr-0 sm:mr-4">
                                <label className="font-lato font-normal text-[14px] texl-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="yesReason4"
                                        className="mr-2"
                                        checked={yesReasons.yesReason4}
                                        onChange={() => setYesReasons({ ...yesReasons, yesReason4: !yesReasons.yesReason4 })}
                                    />
                                    The agent crafted the perfect trip for me
                                </label>
                                <label className="font-lato font-normal text-[14px] texl-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="yesReason5"
                                        className="mr-2"
                                        checked={yesReasons.yesReason5}
                                        onChange={() => setYesReasons({ ...yesReasons, yesReason5: !yesReasons.yesReason5 })}
                                    />
                                    I was referred to this agent from a friend or family member
                                </label>
                                <label className="font-lato font-normal text-[14px] texl-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="yesReason6"
                                        className="mr-2"
                                        checked={yesReasons.yesReason6}
                                        onChange={() => {
                                            if (yesReasons.yesReason6) {
                                                setYesOtherReason('');
                                            }
                                            setYesReasons({ ...yesReasons, yesReason6: !yesReasons.yesReason6 });
                                        }}
                                    />
                                    Other:
                                    <input
                                        type="text"
                                        value={yesOtherReason}
                                        onChange={handleYesOtherReasonChange}
                                        className="mt-2 px-4 py-2 w-full rounded-lg border border-gray-500 focus:outline-none focus:border-black"
                                        disabled={!yesReasons.yesReason6}
                                    />
                                    {errors.yesOtherReason && <p className="text-tlRed text-xs pt-2">Please provide a reason</p>}
                                </label>
                            </div>
                        </div>
                        {errors.yesReasons && <p className="text-tlRed text-xs pt-4">Please select an option</p>}
                    </div>
                ) : selectedStatus === 'N' ? (
                    <div>
                        <div className="flex items-center justify-center flex-col mt-4">
                            <p className="font-lato font-normal text-[11px] texl-tlDarkGray leading-[24px] tracking-normal mt-10">
                                Why did you decide not to book with <span className="font-bold">{surveyData ? `${surveyData.FirstName}` : ''}</span>?
                            </p>
                        </div>

                        <div className="flex flex-col sm:flex-row justify-center mt-2 text-left text-s">
                            <div className="flex flex-col mr-0 sm:mr-4">
                                <label className="font-lato font-normal text-[14px] texl-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="noReason1"
                                        className="mr-2"
                                        checked={noReasons.noReason1}
                                        onChange={() => setNoReasons({ ...noReasons, noReason1: !noReasons.noReason1 })}
                                    />
                                    Poor communication -I never heard back from this agent
                                </label>
                                <label className="font-lato font-normal text-[14px] texl-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="noReason2"
                                        className="mr-2"
                                        checked={noReasons.noReason2}
                                        onChange={() => setNoReasons({ ...noReasons, noReason2: !noReasons.noReason2 })}
                                    />
                                    The agent didn't offer enough value or knowledge about my trip
                                </label>
                                <label className="font-lato font-normal text-[14px] texl-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="noReason3"
                                        className="mr-2"
                                        checked={noReasons.noReason3}
                                        onChange={() => setNoReasons({ ...noReasons, noReason3: !noReasons.noReason3 })}
                                    />
                                    Working with this agent was expensive
                                </label>
                            </div>
                            <div className="flex flex-col mt-4 sm:mt-0 mr-0 sm:mr-4">
                                <label className="font-lato font-normal text-[14px] texl-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="noReason4"
                                        className="mr-2"
                                        checked={noReasons.noReason4}
                                        onChange={() => setNoReasons({ ...noReasons, noReason4: !noReasons.noReason4 })}
                                    />
                                    I ended up not taking the trip
                                </label>
                                <label className="font-lato font-normal text-[14px] texl-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="noReason5"
                                        className="mr-2"
                                        checked={noReasons.noReason5}
                                        onChange={() => setNoReasons({ ...noReasons, noReason5: !noReasons.noReason5 })}
                                    />
                                    I planned the trip by my own
                                </label>
                                    <label className="mt-2">
                                        <input
                                            type="checkbox"
                                            name="noReason6"
                                            className="font-lato font-normal text-[14px] texl-tlDarkGray leading-[24px] tracking-normal mt-2"
                                            checked={noReasons.noReason6}
                                            onChange={() => {
                                                if (noReasons.noReason6) {
                                                    setNoOtherReason('');
                                                }
                                                setNoReasons({ ...noReasons, noReason6: !noReasons.noReason6 });
                                            }}
                                        />
                                        Other:
                                        <input
                                            type="text"
                                            value={noOtherReason}
                                            onChange={handleNoOtherReasonChange}
                                            className="mt-2 px-4 py-2 w-full rounded-lg border border-gray-500 focus:outline-none focus:border-black"
                                            disabled={!noReasons.noReason6}
                                        />
                                        {errors.noOtherReason && <p className="text-tlRed text-xs pt-2">Please provide a reason</p>}
                                    </label>

                            </div>
                        </div>
                        {errors.noReasons && <p className="text-tlRed text-xs pt-4">Please select an option</p>}
                    </div>
                ) : null}


                <div className="flex justify-center mt-4">
                    <button
                        onClick={handleSubmit}
                        className="bg-tlBlue text-white px-6 py-3 rounded-full font-bold uppercase text-sm tracking-wider hover:bg-blue-700 focus:outline-none"
                    >
                        Submit
                    </button>
                </div>

                {error && <p className="text-tlRed mt-2">{error}</p>}
            </div>
        </div>
    );
}

export default SurveyLanding;
