// src/ReviewsLanding.js
import { useEffect, useState } from 'react';
import StarRatings from 'react-star-ratings';
import '../App.css';
import ApiMethods from '../ApiHelper/ApiMethods';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';


function ReviewsLanding() {
    const location = useLocation();
    const { appsGuid: paramsAppsGuid } = useParams();
    const appsGuid = location.state?.appsGuid || paramsAppsGuid;
    const navigate = useNavigate();

    const [rating, setRating] = useState(0);
    const [recommend, setRecommend] = useState(null);
    const [reviewTitle, setReviewTitle] = useState('');
    const [reviewDescription, setReviewDescription] = useState('');
    const [reviewLocation, setReviewLocation] = useState('');
    const [reviewNickname, setReviewNickname] = useState('');
    const [reviewEmail, setReviewEmail] = useState('');
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});
    const [reviewData, setReviewData] = useState(null);
    const [destinationsData, setDestinationsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reviewDestination, setReviewDestination] = useState('');
    const [reviewDestinationId, setReviewDestinationId] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [starDimension, setStarDimension] = useState('50px');

    const changeRating = (newRating) => {
        setRating(newRating);
    };

    const handleRecommendation = (recommendation) => {
        setRecommend(recommendation);
    };

    const handleTitleChange = (event) => {
        setReviewTitle(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setReviewDescription(event.target.value);
    };

    const handleDestinationChange = (event) => {
        const value = event.target.value;
        setReviewDestination(value);

        // Filter suggestions based on the input value
        const filteredSuggestions = destinationsData.filter(destination =>
            destination.name.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestions(filteredSuggestions);

        // Clear the destination ID if the user is typing
        setReviewDestinationId('');
    };

    const handleLocationChange = (event) => {
        setReviewLocation(event.target.value);
    };

    const handleNicknameChange = (event) => {
        setReviewNickname(event.target.value);
    };

    const handleEmailChange = (event) => {
        setReviewEmail(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevents the default form submission behavior
        setErrors({});
        setError('');

        let newErrors = {};

        if (rating === 0) newErrors.rating = true;
        if (recommend === null) newErrors.recommend = true;
        if (reviewTitle.trim() === '') newErrors.reviewTitle = true;
        if (reviewTitle.length > 100) newErrors.reviewTitleLength = true;
        if (reviewDescription.trim() === '') newErrors.reviewDescription = true;
        if (reviewDescription.length > 2000) newErrors.reviewDescriptionLength = true;
        if (reviewDestination.trim() === '' || !reviewDestinationId) newErrors.reviewDestination = true;
        if (reviewLocation.trim() === '') newErrors.reviewLocation = true;
        if (reviewNickname.trim() === '') newErrors.reviewNickname = true;
        if (reviewNickname.length > 50) newErrors.reviewNicknameLength = true;
        if (reviewEmail.trim() === '') newErrors.reviewEmail = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(reviewEmail.trim())) newErrors.reviewEmailValidation = true;

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setError('All fields must be completed before submitting.');
            return;
        }

        const submittedInfo = {
            AppsGuid: appsGuid,
            Rating: rating,
            Recommend: recommend,
            Title: reviewTitle,
            Review: reviewDescription,
            DestinationName: reviewDestination,
            DestinationId: reviewDestinationId,
            Nickname: reviewNickname,
            Email: reviewEmail,
            Location: reviewLocation,
        };

        try {
            const response = await ApiMethods.submitReview(submittedInfo);
            navigate('/thankyou');
        } catch (error) {
            setError('Error submitting review. Please try again.');
        }
    };

    //Stars dimensions
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setStarDimension('25px');
            } else {
                setStarDimension('50px');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Getting data from the API
    useEffect(() => {
        const fetchReviewData = async () => {
            try {
                await ApiMethods.login();
                const reviewResponse = await ApiMethods.getReview(appsGuid);
                const reviewParsedData = JSON.parse(reviewResponse.data);
                setReviewData(reviewParsedData);

                if (!reviewParsedData.ReviewsEligible && reviewParsedData.Agency.RecordType === "GTC") {
                    navigate('/error', {
                        state: {
                            header: "We're sorry!",
                            message: "The Global Travel Collection travel agent you are looking for does not yet have a published profile.",
                            linkPrefix: "Please visit",
                            linkText: "globaltravelcollection.com/contact-us",
                            linkHref: "https://www.globaltravelcollection.com/contact",
                            linkSuffix: "to be connected with your agent. Thank you.",
                        },
                    });
                    return;
                }

                if (!reviewParsedData.ReviewsEligible && reviewParsedData.Agency.RecordType === "Barrhead") {
                    navigate('/error', {
                        state: {
                            header: "We're sorry!",
                            message: "The Barrhead travel agent you are looking for does not yet have a published profile.",
                            linkPrefix: "Please visit",
                            linkText: "barrheadtravel.co.uk",
                            linkHref: "https://www.barrheadtravel.co.uk/",
                            linkSuffix: "to be connected with your agent. Thank you.",
                        },
                    });
                    return;
                }

                if (!reviewParsedData.ReviewsEligible && reviewParsedData.Agency.RecordType === "Altour") {
                    navigate('/error', {
                        state: {
                            header: "We're sorry!",
                            message: "The Altour travel agent you are looking for does not yet have a published profile.",
                            linkPrefix: "Please visit",
                            linkText: "altour.com",
                            linkHref: "https://www.altour.com",
                            linkSuffix: "to be connected with your agent. Thank you.",
                        },
                    });
                    return;
                }

                const destinationsResponse = await ApiMethods.getDestinations(appsGuid);
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(destinationsResponse, 'application/xml');
                // Debug XML structure
                const destinationsParsedData = Array.from(xmlDoc.getElementsByTagName('ListDestinations')).map(dest => ({
                    id: dest.getElementsByTagName('DestinationId')[0]?.textContent,
                    name: dest.getElementsByTagName('Destination')[0]?.textContent,
                }));

                setDestinationsData(destinationsParsedData);

                setLoading(false);
            } catch (error) {
                setError('Error fetching data.');
                setLoading(false);
                navigate('/wrongUrl');
            }
        };

        fetchReviewData();
    }, []);


    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <TailSpin
                    height="100"
                    width="100"
                    color="#0B829E"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                />
            </div>
        );
    }

    const profilePhotoUrl = reviewData ? reviewData.ProfilePhoto : '';
    const headerPhotoUrl = reviewData ? reviewData.Agency.ReviewsLogo : '';

    return (
        <div className="ReviewsLanding">
            <header className="bg-white shadow-md p-10 text-center relative">
                <a href={reviewData.Agency.HomePageLink} target="_blank" rel="noopener noreferrer">
                    <img
                        src={headerPhotoUrl}
                        alt=""
                        className="text-center"
                    />
                </a></header>

            <div className="bg-white shadow-md p-20 mt-24 mb-10 w-11/12 max-w-2xl mx-auto text-center relative">
                <div className="relative">
                    <img
                        src={profilePhotoUrl}
                        alt="Profile"
                        className="absolute top-[-150px] left-1/2 transform -translate-x-1/2 w-32 h-32 object-cover rounded-lg shadow-lg"
                    />
                </div>
                <h2 className="text-lg mb-2">Please review your experience working with</h2>
                <h1 className="text-3xl font-bold text-tlBlue">
                    {reviewData ? `${reviewData.FirstName} ${reviewData.LastName}` : ''}
                </h1>
                <p className="text-m mt-10 text-blue-900">
                    How many stars would you give <span className="font-bold">{reviewData ? `${reviewData.FirstName} ${reviewData.LastName}` : ''}</span> as an agent? <span className={`font-bold ${errors.rating ? 'text-tlRed' : 'text-tlBlue'}`}>*</span>
                </p>
                <div className="flex items-center justify-center flex-row mt-4">
                    <StarRatings
                        rating={rating}
                        starRatedColor="#FFD700"
                        starEmptyColor="#DDD"
                        starHoverColor="#FFD700"
                        changeRating={changeRating}
                        numberOfStars={5}
                        starDimension={starDimension}
                        starSpacing="1px"
                        starRatedClassName="star-rated"
                    />
                </div>

                <div className="flex items-center justify-center flex-col mt-4">
                    <p className="text-m mt-10 text-blue-900">
                        Would you recommend <span className="font-bold">{reviewData ? `${reviewData.FirstName} ${reviewData.LastName}` : ''}</span> to a friend or family member? <span className={`font-bold ${errors.recommend ? 'text-tlRed' : 'text-tlBlue'}`}>*</span>
                    </p>
                    <div className="flex justify-between mt-4">
                        <button
                            className={`border-2 border-tlBlue rounded-full px-6 py-2 transition duration-300 ${recommend === 'Y' ? 'bg-tlBlue text-white' : 'bg-white text-tlBlue hover:bg-tlBlue hover:text-white'}`}
                            onClick={() => handleRecommendation('Y')}>
                            YES
                        </button>
                        <button
                            className={`border-2 border-tlBlue rounded-full px-6 py-2 transition duration-300 ml-4 ${recommend === 'N' ? 'bg-tlBlue text-white' : 'bg-white text-tlBlue hover:bg-tlBlue hover:text-white'}`}
                            onClick={() => handleRecommendation('N')}>
                            NO
                        </button>
                    </div>
                </div>

                <div className="flex items-center justify-center flex-col mt-4">
                    <p className="text-m mt-10 text-blue-900 text-center md:text-left">
                        Please review your experience working with <span className="font-bold">{reviewData ? `${reviewData.FirstName} ${reviewData.LastName}` : ''}</span>.
                    </p>
                    <p className="text-xs text-center md:text-left mt-4">
                        Provide a review of your experience and help other {reviewData.Agency.RecordType === 'Barrhead' ? 'travellers' : 'travelers'} learn about the service you received
                        from {reviewData.FirstName}. From the early planning stages to the trip itself, please take into account all phases of the
                        process when writing your review.
                    </p>

                    <h2 className="font-bold pt-10 text-blue-900">TITLE <span className={`font-bold ${errors.reviewTitle ? 'text-tlRed' : 'text-tlBlue'}`}>*</span></h2>
                    <input
                        type="text"
                        value={reviewTitle}
                        onChange={handleTitleChange}
                        className={`mt-2 px-4 py-2 w-full rounded-full border ${errors.reviewTitle ? 'border-tlRed' : 'border-gray-500'} focus:outline-none focus:border-black`}
                        placeholder="The best trip of our lives!"
                    />
                    {errors.reviewTitleLength && <p className="text-tlRed text-xs">Please enter no more than 100 characters</p>}

                    <h2 className="font-bold pt-10 text-blue-900">REVIEW <span className={`font-bold ${errors.reviewDescription ? 'text-tlRed' : 'text-tlBlue'}`}>*</span></h2>
                    <textarea
                        value={reviewDescription}
                        onChange={handleDescriptionChange}
                        className={`mt-2 px-4 py-2 w-full h-24 rounded-lg border ${errors.reviewDescription ? 'border-tlRed' : 'border-gray-500'} focus:outline-none focus:border-black`}
                    />
                    {errors.reviewDescriptionLength && <p className="text-tlBlue text-xs">Please enter no more than 2000 characters</p>}
                </div>


                <div className="flex flex-col md:flex-row mt-4 pt-10">
                    <div className="w-full md:w-1/2 mb-4 md:mb-0 md:mr-2">
                        <h2 className="font-bold text-blue-900 text-center md:text-left">DESTINATION <span className={`font-bold ${errors.reviewDestination ? 'text-tlRed' : 'text-tlBlue'}`}>*</span></h2>
                        <input
                            type="text"
                            value={reviewDestination}
                            onChange={handleDestinationChange}
                            className={`mt-2 px-4 py-2 w-full rounded-full border ${errors.reviewDestination ? 'border-tlRed' : 'border-gray-500'} focus:outline-none focus:border-black`}
                            placeholder="Where did you travel?"
                        />
                        {suggestions.length > 0 && (
                            <ul className="absolute z-10 bg-white border border-gray-500 rounded-lg mt-2 w-full md:w-1/2">
                                {suggestions.map((suggestion) => (
                                    <li
                                        key={suggestion.id}
                                        onClick={() => {
                                            setReviewDestination(suggestion.name);
                                            setReviewDestinationId(suggestion.id);
                                            setSuggestions([]);
                                        }}
                                        className="cursor-pointer px-4 py-2 hover:bg-gray-200"
                                    >
                                        {suggestion.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {errors.reviewDestination && <p className="text-tlRed text-xs text-center md:text-left">Please enter the destination you visited</p>}
                    </div>
                    <div className="w-full md:w-1/2 md:ml-2">
                        <h2 className="font-bold text-blue-900 text-center md:text-left">YOUR LOCATION <span className={`font-bold ${errors.reviewLocation ? 'text-tlRed' : 'text-tlBlue'}`}>*</span></h2>
                        <input
                            type="text"
                            value={reviewLocation}
                            onChange={handleLocationChange}
                            className={`mt-2 px-4 py-2 w-full rounded-full border ${errors.reviewLocation ? 'border-tlRed' : 'border-gray-500'} focus:outline-none focus:border-black`}
                            placeholder="City, State"
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row mt-4 pt-10">
                    <div className="w-full md:w-1/2 mb-4 md:mb-0 md:mr-2">
                        <h2 className="font-bold text-blue-900 text-center md:text-left">NICKNAME <span className={`font-bold ${errors.reviewNickname ? 'text-tlRed' : 'text-tlBlue'}`}>*</span></h2>
                        <input
                            type="text"
                            value={reviewNickname}
                            onChange={handleNicknameChange}
                            className={`mt-2 px-4 py-2 w-full rounded-full border ${errors.reviewNickname ? 'border-tlRed' : 'border-gray-500'} focus:outline-none focus:border-black`}
                            placeholder="AgentFan123"
                        />
                        {errors.reviewNicknameLength && <p className="text-tlBlue text-xs text-center md:text-left">Please enter no more than 50 characters</p>}
                        <p className="text-xs pt-1 text-blue-900 text-center md:text-left">Displays with your review.</p>
                    </div>
                    <div className="w-full md:w-1/2 md:ml-2">
                        <h2 className="font-bold text-blue-900 text-center md:text-left">EMAIL <span className={`font-bold ${errors.reviewEmail ? 'text-tlRed' : 'text-tlBlue'}`}>*</span></h2>
                        <input
                            type="text"
                            value={reviewEmail}
                            onChange={handleEmailChange}
                            className={`mt-2 px-4 py-2 w-full rounded-full border ${errors.reviewEmail ? 'border-tlRed' : 'border-gray-500'} focus:outline-none focus:border-black`}
                            placeholder="Email"
                        />
                        {errors.reviewEmailValidation && <p className="text-tlRed text-xs text-center md:text-left">Please enter a valid email address</p>}
                        <p className="text-xs pt-1 text-blue-900 text-center md:text-left"><span className="font-bold">Not public. </span>Only used in cases where we have questions regarding your review.</p>
                    </div>
                </div>


                <div className="flex items-center justify-center flex-col pt-20">
                    <button
                        className="bg-tlBlue hover:bg-blue-900 text-white rounded-full font-bold py-3 px-8"
                        onClick={handleSubmit}>SUBMIT REVIEW</button>
                </div>
                {error && (
                    <div className="mt-4 text-tlRed font-bold">
                        {error}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ReviewsLanding;
