import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import ApiMethods from '../ApiHelper/ApiMethods';
import { TailSpin } from 'react-loader-spinner';

const Home = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const handleUrl = async () => {
            const url = window.location.href;
            let appsGuid = '';
            let requestId = '';
            let userResponse = '';
            let token = '';

            // NEW: Review or Survey
            if (url.match(/(Review|Survey)\/([a-zA-Z0-9-]+)(?:\/([a-zA-Z0-9-]+))?/)) {
                const match = url.match(/(Review|Survey)\/([a-zA-Z0-9-]+)(?:\/([a-zA-Z0-9-]+))?/);
                appsGuid = match[2];
                requestId = match[3] || null;

                try {
                    if (url.includes('Review')) {
                        await ApiMethods.getReview(appsGuid);
                        navigate(`/review/${appsGuid}`, { state: { appsGuid } });
                    } else if (url.includes('Survey')) {
                        await ApiMethods.getSurvey(appsGuid, requestId);
                        navigate(`/survey/${appsGuid}/${requestId}`, { state: { appsGuid, requestId } });
                    }
                } catch (error) {
                    console.error('Error during getReview or getSurvey:', error);
                    navigate('/wrongUrl');
                } finally {
                    setLoading(false);
                }
            }
            // OLD: Review or Survey
            else if (url.match(/Reviews\/(abrangent_review|agent_experience)\.aspx\?([a-z]+)=([a-zA-Z0-9-]+)/)) {
                const match = url.match(/Reviews\/(abrangent_review|agent_experience)\.aspx\?([a-z]+)=([a-zA-Z0-9-]+)/);
                const pageType = match[1];
                token = match[3];

                try {
                    if (pageType === 'abrangent_review') {
                        await ApiMethods.getReview(token);  // OLD: token is the agt value
                        navigate(`/review/${token}`, { state: { token } });
                    } else if (pageType === 'agent_experience') {
                        await ApiMethods.getSurvey(token, 0); // OLD: no requestId
                        navigate(`/survey/${token}/0`, { state: { token, requestId: 0 } });
                    }
                } catch (error) {
                    console.error('Error during getReview or getSurvey:', error);
                    navigate('/wrongUrl');
                } finally {
                    setLoading(false);
                }
            }
            // CONTACTED 24HS (NEW or OLD)
            else if (url.match(/Contacted\/([a-zA-Z0-9-]+)\/([a-zA-Z0-9-]+)\?rsp=([YNM])/) ||
                url.match(/Reviews\/thank_you.aspx\?pg=24&m=[0-9]&rsp=([YNM])&req=([a-zA-Z0-9-]+)/)) {
                const match = url.match(/Contacted\/([a-zA-Z0-9-]+)\/([a-zA-Z0-9-]+)\?rsp=([YNM])/) ||
                    url.match(/Reviews\/thank_you.aspx\?pg=24&m=[0-9]&rsp=([YNM])&req=([a-zA-Z0-9-]+)/);
                appsGuid = match[1] || match[2];
                requestId = match[2] || 0;
                userResponse = match[3];

                try {
                    await ApiMethods.postContacted(appsGuid, requestId, userResponse);
                    navigate('/thankyou');
                } catch (error) {
                    navigate('/thankyou');
                } finally {
                    setLoading(false);
                }
            }
            // PURLS (NEW and OLD)
            else if (url.match(/https?:\/\/agents\.(travelleaders|canadiantravelagents|globaltravelcollection)\.com\/([a-zA-Z0-9-]+)/)) {
                const match = url.match(/https?:\/\/agents\.(travelleaders|canadiantravelagents|globaltravelcollection)\.com\/([a-zA-Z0-9-]+)/);
                if (match) {
                    const domain = match[1];
                    appsGuid = match[2];

                    console.log('Captured PURL domain:', domain);
                    console.log('Captured PURL appsGuid:', appsGuid);

                    const customUrlData = `${domain}/${appsGuid}`;

                    try {
                        const response = await ApiMethods.getCustomUrl(customUrlData);
                        if (response.success && response.data) {
                            const data = JSON.parse(response.data);
                            if (data.URL) {
                                window.location.href = data.URL;
                            } else {
                                navigate('/wrongUrl');
                            }
                        } else {
                            navigate('/wrongUrl');
                        }
                    } catch (error) {
                        console.error('Error during getCustomUrl:', error);
                        navigate('/wrongUrl');
                    } finally {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                    navigate('/wrongUrl');
                }
            } else {
                console.error('No matching URL pattern found.');
                setLoading(false);
                navigate('/wrongUrl');
            }
        };

        handleUrl();
    }, [navigate]);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <TailSpin
                    height="100"
                    width="100"
                    color="#0B829E"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                />
            </div>
        );
    }

    return null;
};

export default Home;